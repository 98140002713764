import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, BrowserRouter as Router, Navigate, useLocation } from 'react-router-dom';
//import CustomIntermediateNavigate from './components/CustomIntermediateNavigate';
// import useEventList from './hooks/useEventList';
// import useSessionCheck from './hooks/useSessionCheck';
// import AuthLayout from './pages/auth/dashboard/AuthLayout';
// import CompletedEventPage from './pages/auth/dashboard/CompletedEventPage';
// import CreateEvent from './pages/auth/dashboard/events/CreateEvent';
// import EditEvent from './pages/auth/dashboard/events/EditEvent';

// import GuestUserUploadPage from './pages/auth/dashboard/GuestUserUploadPage';
// import UserDashboard from './pages/auth/dashboard/UserDashboard';
import Dashboard from './pages/dashboard';
import ProductionEventPage from './pages/events';
import GuestUserPage from './pages/guest';
import PageNotFound from './pages/shared/PageNotFound';
// import Signin from './pages/unauth/Signin';
// import Signup from './pages/unauth/Signup';
import { updateState } from './redux/commonSlice';
import CompletedEventPage from './pages/completedvideo';
import SignIn from './pages/unauthv2/signin';
import SignUp from './pages/unauthv2/signup';
import ResendLink from './pages/unauthv2/resendlink';
import ForgotPassword from './pages/unauthv2/forgotpassword';
import ResetPassword from './pages/unauthv2/resetpassword';
import InviteCollaborator from './pages/collaborator';
import UserMediaFiles from './pages/userfiles';
import TeamsDashboard from './pages/teamsdashboard';
import ReactGA from "react-ga4";
import { gaEvents } from './ga/actions';
import RedirectToAppStore from './redirects';
import AppleSignin from './pages/unauthv2/appleSignin';
import { isIos } from './utils/commonServices';
import MemberSignUp from './pages/unauthv2/memberSignup';
import { PERMISSION, uploadCategory } from './constants/constants';
import CustomProtectedRoute from './components/CustomProtectedRoute';
import UserBrandbox from './pages/brandbox';
import { CssBaseline, ThemeProvider } from '@mui/material';
import useCustomTheme from './theme/useCustomTheme';
import GuestReviewPage from './pages/guestreview';
import SigninAction from './pages/unauthv2/signinaction';
import WatchVideoPage from './pages/videopreview';
import Logout from './pages/shared/AuthRedirect';
import AuthRedirect from './pages/shared/AuthRedirect';
import FileUploadComponent from './pages/upload';
import useSequentialChunkUpload from './pages/upload/hooks/useSequentialChunkUpload';
import useUploadFiles from './pages/upload/hooks/useUploadFiles';


// import { useSelector } from 'react-redux';

//lucimoments -----------------------
// const GOOGLE_ANALYTICS_ID="G-WT87Y5QVPK"


ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID)


function App() {

   
  const commonReducer = useSelector((state) => state.root.commonReducer);


 
   const dispatch = useDispatch()
   const [theme] = useCustomTheme()
   const refetchRef = useRef();
   const {
          selectedFiles, setSelectedFiles, 
          uploadsInProgress, setUploadsInProgress,
          uploadStatus,  setUploadStatus,
          progress, setProgress,
          requestController, setRequestController,
          uploadFiles,isLoading, initialUploadState
        } = useUploadFiles()
 
  const uploadProps = {
    selectedFiles, setSelectedFiles, 
    uploadsInProgress, setUploadsInProgress,
    uploadStatus,  setUploadStatus,
    progress, setProgress,
    requestController, setRequestController,
    uploadFiles,isLoading, initialUploadState
  }
   useEffect(()=> {
   
      if(window.localStorage.getItem('token')) {
        // refetchSessionCheck()
        dispatch(updateState({ is_logged: true }));
        
      } else {
        dispatch(updateState({ is_logged: false}));
        
      }
    
   },[window.localStorage.getItem('token')])
   

  //  useEffect(() => {
  //   const handleMessage = (event) => {
   
  //     if (event.origin === 'http://localhost:3001') {
      
  //       if (event.data === 'REQUEST_TOKEN') {
       
  //         const token = localStorage.getItem('token');
        
  //         event.source.postMessage({ token: token , user:{  user_id: commonReducer?.user_id,
  //           user_email: commonReducer?.user_email,
  //           user_name: commonReducer?.user_name,
  //           is_admin: commonReducer?.is_admin,
  //           company_name:commonReducer?.company_name,
  //         loading: commonReducer?.signin_loading} }, event.origin);
  //       }else if(event.data === 'DELETE_TOKEN'){
  //         event.source.postMessage('TOKEN_DELETED', event.origin);
  //         window.localStorage.removeItem("token");
  //         // console.log('delete token lucihub')
  //       }
        
  //       // console.log('lucihub useeffect')
  //     }
  //   };

  //   window.addEventListener('message', handleMessage);
  //   return () => {
  //     window.removeEventListener('message', handleMessage);
  //   };
  // }, []);



  return (
  <ThemeProvider theme={theme}>
      <CssBaseline />
      <>
      <Router >
        {
          !commonReducer.is_logged ?  <Routes>
          <Route path= '/' element={<Navigate to='/signin' />} /> 
          <Route path='/guestuploads' element={<GuestUserPage uploadProps = {uploadProps} />} />  
          <Route path='/guestreview' element={<GuestReviewPage />} /> 
          <Route path='/watchvideo/:id' element={ <WatchVideoPage uploadProps = {uploadProps} /> } />
          <Route path='/auth-redirect' element={ <AuthRedirect/> } />
          {/* <Route path='/signup' element={ <Signup/> } />
          <Route path='/signin' element={ <Signin/> } /> */}
          <Route path='/invite-collaborator' element = {<InviteCollaborator /> } />
          <Route path='/signup' element={ <SignUp/> } />
          <Route path='/member-signup' element={ <MemberSignUp/> } />
          <Route path='/signin-action' element={ <SigninAction/> } />
          <Route path='/applesignin' element={ <AppleSignin/> } />
          <Route path='/signin' element={ <SignIn/> } />
          <Route path='/forgotpassword' element={ <ForgotPassword/> } />
          <Route path='/resendlink' element={<ResendLink/>} />
          <Route path='/resetpassword' element={ <ResetPassword/> } />
          <Route path= '*' element={<Navigate to='/signin' />} />
        </Routes> :
        
          <Routes>
             <Route path= '/' element={<Navigate to='/dashboard' />} /> 
             <Route path='/chunkupload' element={ <FileUploadComponent/> } />
            <Route path='/dashboard' element={ <Dashboard uploadProps = {uploadProps}/> } />
            <Route path='/dashboard/teams' element={ <TeamsDashboard uploadProps = {uploadProps}/> } />
            <Route path='/watchvideo/:id' element={ <WatchVideoPage uploadProps = {uploadProps}/> } />
            <Route path='/auth-redirect' element={ <AuthRedirect/> } />
            {/* <Route path='/watchvideo/:videoSlug' element={ <WatchVideoPage/> } /> */}
            {/* <Route path='/watchvideo/:id' element={ <WatchVideoPage/> } /> */}
            {/* <Route path='/guestuploads' element={isIos() ? <RedirectToAppStore /> : <GuestUserPage />} />   */}
            <Route path='/guestuploads' element={<GuestUserPage uploadProps = {uploadProps} />} />  
            <Route path='/signin-action' element={ <SigninAction/> } />
            <Route path='/invite-collaborator' element = {<RedirectToAppStore /> } />
            <Route path='/realestate-verify' element = {<RedirectToAppStore /> } />
            <Route path='/realestate-resetpassword' element = {<RedirectToAppStore /> } />
            {/* <Route path='/guestuploads' element = {<RedirectToAppStore /> } /> */}
            {/* <Route path='/create-event' element={ <CreateEvent /> } /> 
            <Route path='/edit-event' element={ <EditEvent /> } />
            <Route path='/hdsbvjdsdfdsfsfhbv' element={<CustomIntermediateNavigate />} />
            <Route path='/completedevent' element={ <CompletedEventPage/> } /> */}
            <Route path='/productionevent' element={ <ProductionEventPage uploadProps = {uploadProps} /> } />
            <Route path='/completedevent' element={ <CompletedEventPage uploadProps = {uploadProps} /> } /> 
            {/* <Route 
              path='/completedevent' 
              element={<CustomProtectedRoute
                permission={PERMISSION.PROJECT.REVISION.PAGE_VIEW}
              >
                
              </CustomProtectedRoute> } /> */}
            {/* <Route path='/myfiles' element={ <UserMediaFiles/> } /> */}
            <Route
              path='/myfiles'
              element={<UserMediaFiles uploadProps = {uploadProps}/>} />
            <Route
              path='/brandbox'
              element={<UserBrandbox uploadProps = {uploadProps}/>} />
            {/* <Route path="*" element={<Navigate to='/dashboard' />} /> */}
            <Route path='*' element={ <PageNotFound/> } />
            {/* <Route path= '*' element={<Navigate to='/error-notfound-redirect' />} />  */}
          </Routes>  
        
        }
       
      </Router>
      </>
        
    </ThemeProvider>
    );
}

export default App;
