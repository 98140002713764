import React, { useEffect, useState } from 'react'
import { getInitials } from '../../../utils/commonServices'
import { Avatar, Box, Collapse, IconButton, ListItemText, Menu, MenuList, Switch, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { useDispatch, useSelector } from 'react-redux';
import { updateTeamState } from '../../../redux/teamSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateApproverRole, updateButterflyAccess, updateOrganisationMemberRole } from '../actions';
import usePermissions from '../../../hooks/usePermissions';
import { PERMISSION } from '../../../constants/constants';
import { colors } from '../../../constants/staticData';
// import { customTheme as theme } from '../../../theme/customTheme';
import useCustomTheme from '../../../theme/useCustomTheme';

let counter = 0;

const OrganisationMembersList = ({member, index, searchedArray, setTestmembers, roleOptions}) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRole, setSelectedRole] = useState(member?.role?.name);
    const teamsReducer = useSelector((state) => state.root.teamsReducer);
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const permissionsReducer = useSelector((state) => state.permissionsReducer)
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const [hasPermission] = usePermissions()
    const [theme ] = useCustomTheme()
    const [butterflyAccess, setButterflyAccess] = useState(member?.role?.copilot_access)
    const [isApprover, setIsApprover] = useState(member?.is_approver)

    const [numberOfAdmins, setNumberOfAdmins] = useState(searchedArray.filter(member => member?.role?.id == 1).length)

    // useEffect(()=>{
    //   dispatch(updateTeamState({selected_member: member}))
    // },[member])

    // useEffect(()=>{
    //   setSelectedRole(member.role.name)
    // },[member.role.name, member])

  
    useEffect(()=>{
         setNumberOfAdmins(teamsReducer.organization_members?.filter((item) => item.role.id == 1).length)  
    },[searchedArray,teamsReducer.organization_members ])
  
    // console.log({searchedArray}, 'members page')
    // console.log(teamsReducer.organization_members , 'members page')
    // console.log('no of admins', numberOfAdmins)
    // console.log('selected role', selectedRole)
  
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };
  
    const handleRoleSelect = (role, member) => {  
        if (numberOfAdmins === 1 && member?.role?.id == 1){
          dispatch(updateTeamState({assign_role_popup : true, change_admin_flag: true, invite_team_members_popup : false}))
        }else{
        const payload = {
            user_id: member?.id,
            role_id: role?.id
        }
        updateOrganisationMemberRole(payload, dispatch,  setSelectedRole, role,  navigate,{ permissionsReducer: permissionsReducer, commonReducer: commonReducer})
        // setSelectedRole(role.name);
       }
      setAnchorEl(null);
    };

    const handleDeleteMember =()=>{ 
        if(member.role.id == 1){
          if (numberOfAdmins == 1) {
            dispatch(updateTeamState({assign_role_popup : true}))
          } else {
            dispatch(updateTeamState({selected_member: member}))
            dispatch(updateTeamState({remove_member_confirmation_popup : true}))
          }
        }else{
          dispatch(updateTeamState({selected_member: member}))
          dispatch(updateTeamState({remove_member_confirmation_popup : true}))
        }
      dispatch(updateTeamState({invite_team_members_popup : false}))   
    }

    const copilotAccessChangeHandler = (e)=>{
      const payload = {
        user_id : member?.id,
        access: !member?.role?.copilot_access
      }
      updateButterflyAccess(payload, dispatch, navigate, {permissionsReducer: permissionsReducer, commonReducer: commonReducer})
      if(e.target.checked == true){
        setButterflyAccess(true)
      }else{
        setButterflyAccess(false)
      }
    }

    const setApproverChangeHandler = (e)=>{
      const payload = {
        user_id : member?.id,
        is_approver: !member?.is_approver
      }
      updateApproverRole(payload, dispatch, navigate, {permissionsReducer: permissionsReducer, commonReducer: commonReducer})
      if(e.target.checked == true){
        setIsApprover(true)
      }else{
        setIsApprover(false)
      }
    }
   

  return (
    <div style={{width:{xs:'90%', md:'80%'}, marginTop:'5px'}}>

        {searchedArray?.length > 0 &&
            <>
            <Box mb={1} className='flex a-center' gap={2}>
    
                                <Avatar
                                    sx={{
                                    borderRadius: '5px',
                                     backgroundColor: colors[index % colors.length],
                                   // backgroundColor: color,
                                    color: 'white',
                                    width: { xs: '18px', sm: '20px', md: '22px', lg: '23.5px', xl: '35px' },
                                    height: { xs: '18px', sm: '20px', md: '22px', lg: '23.5px', xl: '35px' },
                                    paddingRight: 0,
                                    marginRight: 0,
                                    fontSize: { xs: '7px', md: '8px', lg: '8px', xl: '12px' },
                                    textTransform: 'uppercase',
                                    fontFamily: 'Rubik',
                                    fontWeight:600
                                    }}
                                >
                                    {getInitials(member.name)}
                                </Avatar>
                <Box flex={8} className='flex col' sx={{fontSize:{xs:'9px', md:'10px',lg:'13px', xl:'18px'}, fontWeight:600, fontFamily:'Rubik', color:'#000000'}}>
                   {member.name}
                    <br />
                    <Box component='span' sx={{fontSize:{xs:'9px', md:'10px',lg:'11px', xl:'13.5px'}, fontWeight:500}}>{member.email}</Box>
                </Box>

                <Box flex={6} className='flex a-center' style={{justifyContent:'end'}}>
                    <Typography sx={{ fontSize: {xs:'9px', md:'10px',lg:'12px', xl:'16px'}, fontWeight: 500, color: member?.role?.change_allowed ? '#000000': 'gray', fontFamily:'Rubik', textAlign:'right'}}>
                        {member?.role?.name}
                    </Typography>
                {teamsReducer.invite_team_members_popup == true && 
                    <IconButton edge="end" disabled = {teamsReducer.is_loading || !member?.role?.change_allowed}  onClick={handleClick} >
                        <ExpandMoreIcon  sx={{ fontSize: {xs:'10px', md:'12px',lg:'14px', xl:'16px'}}} />
                    </IconButton>
                }
              
                </Box>

               {teamsReducer.invite_team_members_popup == true &&  member?.role?.show_toggle ? <Box flex={2} className='flex a-center' style={{justifyContent:'end'}}>
                  <Switch size="small"   checked={butterflyAccess} onChange={(e)=>copilotAccessChangeHandler(e)}/>
                </Box> : <Box flex={3} className='flex a-center' style={{justifyContent:'end'}}>
                
                </Box>}

                {teamsReducer.invite_team_members_popup == true && commonReducer?.approver_allowed == true &&  commonReducer?.is_admin ? <Box flex={2} className='flex a-center' style={{justifyContent:'end'}}>
                  <Switch size="small"   checked={isApprover} onChange={(e)=>setApproverChangeHandler(e)}/>
                </Box> : <Box flex={3} className='flex a-center' style={{justifyContent:'end'}}>
                
                </Box>}
            </Box>
     
            </>}
    

         <Menu
            id="role-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
               
                vertical: 0,
                horizontal: 150
            }}
          >
          {hasPermission(PERMISSION.ORGANISATION.MEMBER.UPDATE) &&  roleOptions?.map((role, index) => (
              <MenuItem key={index} onClick={() => handleRoleSelect(role, member)} sx={{'&:hover':{backgroundColor:`${theme.palette.primary.main}70`, color:'black'}, backgroundColor: selectedRole == role.name && `${theme.palette.primary.main}99`}}>
              {/* {testmembers.filter(item => item.id == member.id)[0]?.role} */}
                <ListItemText><Typography sx={{ fontFamily: 'Rubik', fontSize: { xs: '8px', md: '10px', lg: '11px', xl: '13px', fontWeight: 600, color: '#000000' } }}>Set as {role.name}</Typography></ListItemText>
                {selectedRole == role.name && <CheckRoundedIcon style={{fontSize:'10px', fontWeight:600}}/>}
              </MenuItem>
            ))}
           {hasPermission(PERMISSION.ORGANISATION.MEMBER.DELETE) &&   <MenuItem onClick={() => handleDeleteMember()} sx={{'&:hover':{backgroundColor:`${theme.palette.primary.main}70`, color:'black'}}}>
                <ListItemText><Typography sx={{ fontFamily: 'Rubik', fontSize: { xs: '8px', md: '10px', lg: '11px', xl: '13px', fontWeight: 600, color: '#CE0101' } }}>Remove from Organization</Typography></ListItemText>
            </MenuItem>}
     
        </Menu> 
    </div>
  )
}

export default OrganisationMembersList