import { apiConstant } from "./apiConstants"
import { Request } from "./service"
import { UnauthRequest } from "./unauthService"

export const testApi = () => {
   
    return Request(apiConstant.testApi, 'Get')
}


// export const userLogin = (params) => {
//     return Request(apiConstant.user.login, 'Get', params, false)
// }

//************************SESSIONS API SERVICES**************************************** */

export const checkSession= () => {
    return Request(apiConstant.session.checkSessionValidity, 'Get')
}

//********************USER API SERVICES************************************** */

export const userSignup= (params) => {
    return Request(apiConstant.user.signup, 'Post', params, true)
}
export const appleSign= (params) => {
    return Request(apiConstant.user.appleSign, 'Post', params, true)
}
export const verifyAppleUser= (params) => {
    return Request(apiConstant.user.verifyAppleUser, 'Get', params, false)
}

// export const userSignin= (params) => {
//     return Request(apiConstant.user.signin, 'Get', params, false)
// }

export const userSignin= (params) => {
    return Request(`${apiConstant.user.signin}?product_id=${params.product_id}`, 'Get', params.param, false)
}

export const checkEmail = (params) => {
    return Request(apiConstant.user.checkEmail, 'Get', params, false)
}
export const resendLink = (params) => {
    return Request(apiConstant.user.resendLink, 'Get', params, false )
}


export const forgotPassword = (params) => {
    return Request(apiConstant.user.forgotPassword, 'Get', params, false)
}

export const checkVerificationToken = (params) => {
    return Request(apiConstant.user.checkVerificationToken, 'Get', params, false)
}

export const verifyUserMail = (params) => {
    return Request(apiConstant.user.verifyUserMail, 'Get', params, false)
}

export const resetPassword = (params) => {
    return Request(apiConstant.user.resestPassword, 'Put', params, true)
}

export const deleteUser= (params) => {
    return Request(`${apiConstant.user.deleteUser}/${params.id}`, 'Delete', params.param, true)
}

export const memberInvitationApi = (params, header) => {
    return Request(apiConstant.user.memberInvitation, 'Put', params, true, null, header)
}

export const userRoleApi = (params) => {
    return Request(apiConstant.user.userRole, 'Get', params, false)
}

export const approveUserApi = (params) => {
    return Request(apiConstant.user.approveUser, 'Get', params, false)
}

export const checkSessionApi = (params) => {
    return Request(apiConstant.user.checkSession, 'Get', params, false)
}


//************************EVENTS API SERVICES**************************************** */

export const createEvent= (params) => {
    return Request(apiConstant.event.create, 'Post', params, true)
}

export const editEvent= (params) => {
    return Request(`${apiConstant.event.create}/${params.id}`, 'Put', params.param, true)
}
export const cancelEvent= (params) => {
    return Request(apiConstant.event.cancelEvent, 'Put', params, false)
}

export const addEvent = (params) => {
    return Request(apiConstant.event.addEvent, "Post", params, true);
  };



export const eventsList= () => {
    return Request(apiConstant.event.create, 'Get')
}

export const getTeamEventsListApi = (params) => {
    return Request(apiConstant.event.create, 'Get', params, false)
}

export const eventDetails= (params) => {
    return Request(`${apiConstant.event.create}/${params.id}`, 'Get')
}

export const uploadVideo= (params) => {
    return Request(apiConstant.event.uploadVideo, 'Post', params.data, true, params.progress, params.header, params.controller )
}

export const guestUploadVideo= (params) => {
    return UnauthRequest(apiConstant.event.uploadVideo, 'Post', params.data, true, params.progress, params.header, params.controller )
}

export const uploadList= (params) => {
    return Request(apiConstant.event.uploadList, 'Get', params, false)
}

export const uploadListToLinkEvent = (params) => {
    return Request(apiConstant.event.uploadListToLinkEvent, 'Get', params, false)
}

export const importFiles= (params) => {
    return Request(apiConstant.event.importFiles, 'Post', params, true)
}

export const allMediaFiles = () => {
    return Request(apiConstant.event.allMediaFiles, 'Get')
}

export const markViewed = (params) => {
    return Request(apiConstant.event.markViewed, 'Put', params, false)
}

export const createZippedMedia= (params) => {
    return Request(apiConstant.event.createZippedMedia, 'Post', params, true)
}

export const downloadZippedMedia = (params) => {
    return Request(apiConstant.event.downloadZippedMedia, 'Get', params, false, )
}

export const downloadMedia = (params) => {
    return Request(apiConstant.event.downloadMedia, 'Get', params, false, )
}

export const favoriteMedia = (params) => {
    return Request(apiConstant.event.favoriteMedia, 'Post', params, false, )
}

export const deleteMedia= (params) => {
    return Request(`${apiConstant.event.uploadList}/${params.id}`, 'Delete', params.param, true)
}

// export const guestEventDetails= (params) => {
//     return Request(apiConstant.event.guestEvent, 'Get', params, false)
// }

export const guestEventDetails= (params) => {
    return UnauthRequest(apiConstant.event.guestEvent, 'Get', params, false)
}

export const acceptFinalVideo= (params) => {
    return Request(apiConstant.event.acceptFinalVideo, 'Put', params, false)
}

export const myMediaFileProjectsApi= () => {
    return Request(apiConstant.event.myMediaFileProjects , 'Get')
}


export const myMediaFilesOfProjectApi = (params) => {
    return Request(apiConstant.event.myMediaFilesOfProject, 'Get', params, false)
}

export const updateProjectApproverRoleApi = (params) => {
    return Request(`${apiConstant.event.addProjectApprover}?user_id=${params.user_id}&approval_permission=${params.approval_permission}&event_id=${params.event_id}`, 'Put')
}

//   project roles api///

export const viewProjectMembersApi = (params) => {
    return Request(apiConstant.event.viewProjectMember, 'Get', params, false )
}

export const addProjectMemberApi = (params) => {
    return Request(apiConstant.event.addProjectMember , 'Post', params, true)
}

export const updateProjectMemberRoleApi = (params) => {
    return Request(apiConstant.event.updateProjectMemberRole, 'Put', params, true)
}

export const changeProjectAdminApi = (params) => {
    return Request(apiConstant.event.changeProjectAdmin, 'Put', params, true)
}

export const removeProjectAdminApi = (params) => {
    return Request(apiConstant.event.removeProjectAdmin, 'Put', params, true)
}


export const removeProjectMemberApi = (params) => {
    return Request( apiConstant.event.removeProjectMember, 'Delete', params, true)
}


//************************OPTIONS API SERVICES**************************************** */

export const genreList = (params) => {
    return Request(apiConstant.options.genre, 'Get', params, false)
}

export const toneList = (params) => {
    return Request(apiConstant.options.tone, 'Get', params, false)
}

export const phoneCountRanges = (params) => {
    return Request(apiConstant.options.phoneCount, 'Get', params, false)
}
export const qualityOptionList = (params) => {
    return Request(apiConstant.options.qualityOption, "Get", params, false);
  };
  export const eventTypeList = (params) => {
    return Request(apiConstant.options.eventType, 'Get', params, false)
}

export const videoLengthList = (params) => {
    return Request(apiConstant.options.videoLength, 'Get', params, false)
}
  export const eventStatusType = (params) => {
    return Request(apiConstant.options.statusType, 'Get', params, false)
}

export const getRolesApi = (params) => {
    return Request(`${apiConstant.options.role}?product_id=${params.product_id}`, 'Get')
}

export const getAssetOptionsApi = (params) => {
    return Request(`${apiConstant.options.asset}`, 'Get' , params, false)
}
// ////////////////////////////////consent api//////////////////////////////////

export const consentDetail = (params) => {
    return Request(apiConstant.consents.consentDetail, 'Get', params, false )
}

export const eventVideoUploadConsent= (params) => {
    return Request(apiConstant.consents.eventVideoUploadConsent, 'Post', params, true)
}

////////////////////////////////revision////////////////////////////////

export const videoUrl = (params) => {
    return Request(apiConstant.revision.videoUrl, 'Get', params, false )
}
export const checkNewVideo = (params) => {
    return Request(apiConstant.revision.checkNewVideo, 'Get', params, false )
}
export const createRevision = (params) => {
    return Request(apiConstant.revision.createRevision, 'Post', params, true )
}
export const getRevisionLogsApi = (params) => {
    return Request(apiConstant.revision.getRevisionLogs, 'Get', params, false )
}
export const createRevisionLogs = (params) => {
    return Request(`${apiConstant.revision.getRevisionLogs}?user_id=${params.id}`, 'Post', params.param, true )
}
export const submitRevision = (params) => {
    return Request(`${apiConstant.revision.submitRevision}?revision_id=${params.id}`, 'Put' )
}
export const disableRevisionTutorial = () => {
    return Request(apiConstant.revision.disableRevisionTutorial, 'Put' )
}

export const updateRevisionLogsApi = (params) => {
    return Request(`${apiConstant.revision.getRevisionLogs}/${params.id}`, 'Put', params.param, true )
}

export const deleteRevisionLogApi = (params) => {
    return Request(`${apiConstant.revision.deleteLogs}/${params.id}`, 'Delete', params.param, true)
}

export const sendForApprovalApi = (params) => {
    return Request(`${apiConstant.revision.sendForApproval}?final_video_id=${params.final_video_id}`, 'Put')
}

////////////////////////organisation apis/////////////////////////////

export const viewOrganisationMembersApi = (params) => {
    return Request(apiConstant.organisation.viewOrganisationMember, 'Get', params, false )
}

export const addOrganisationMemberApi = (params) => {
    return Request(apiConstant.organisation.addOrganisationMember , 'Post', params, true)
}

export const updateOrganisationMemberRoleApi = (params) => {
    return Request(apiConstant.organisation.updateOrganisationMemberRole, 'Put', params, true)
}

export const changeOrganisationAdminApi = (params) => {
    return Request(apiConstant.organisation.changeAdmin, 'Put', params, true)
}

export const removeOrganisationAdminApi = (params) => {
    return Request(apiConstant.organisation.removeAdmin, 'Put', params, true)
}

export const removeOrganisationMemberApi = (params) => {
    return Request(`${apiConstant.organisation.removeOrganisationMember}?user_id=${params.user_id}`, 'Put')
}

export const updateOrganisationNameApi = (params) => {
    return Request(apiConstant.organisation.updateOrganisationName, 'Put', params, true)
}

export const updateButterflyAccessApi = (params) => {
    return Request(apiConstant.organisation.butterflyAccess, 'Put', params, true)
}

export const updateApproverRoleApi = (params) => {
    return Request(`${apiConstant.organisation.addApprover}?user_id=${params.user_id}&is_approver=${params.is_approver}`, 'Put')
}
////////////////////teams api/////////////////////////////////

export const createTeamApi = (params) => {
    return Request(apiConstant.team.createTeam , 'Post', params, true)
}

export const getAllTeamsApi = (params) => {
    return Request(apiConstant.team.createTeam, 'Get', params, false )
}

export const updateTeamPrivacyApi = (params) => {
    return Request(apiConstant.team.updateTeamPrivacy, 'Put', params, true)
}

export const renameTeamApi = (params) => {
    return Request(apiConstant.team.renameTeam, 'Put', params, true)
}

export const removeTeamApi= (params) => {
    return Request( apiConstant.team.removeTeam, 'Delete', params, true)
}

export const addTeamMemberApi = (params) => {
    return Request(apiConstant.team.addTeamMember , 'Post', params, true)
}

export const viewTeamMembersApi = (params) => {
    return Request(`${apiConstant.team.viewTeamMembers}?team_id=${params.team_id}`, 'Get' )
}

export const updateTeamMemberRoleApi = (params) => {
    return Request(apiConstant.team.updateTeamMemberRole, 'Put', params, true)
}

export const changeTeamAdminApi = (params) => {
    return Request(apiConstant.team.changeTeamAdmin, 'Put', params, true)
}

export const removeTeamAdminApi = (params) => {
    return Request(apiConstant.team.removeTeamAdmin, 'Put', params, true)
}

export const removeTeamMemberApi = (params) => {
    return Request( apiConstant.team.removeTeamMember, 'Delete', params, true)
}

//////////permission//////////////////////////

export const getPermissionsApi = (params) => {
    return Request(`${apiConstant.permission.getPermission}?product_id=${params.id}`, 'Get')
}


////////////////////////////roles/////////////////////////////

export const createRoleApi = (params) => {
    return Request(apiConstant.roles.role , 'Post', params, true)
}

export const updateRoleApi = (params) => {
    return Request(`${apiConstant.roles.role}/${params.id}`, 'Put', params.params, true)
}

export const showRoleApi = (params) => {
    return Request(`${apiConstant.roles.role}/${params.id}`, 'Get')
}


///////////////////////////////theme///////////////////////////////////////


export const getThemeApi = (params) => {
    return Request(apiConstant.themes.theme, 'Get', params, false )
}


export const createThemeApi = (params) => {
    return Request(apiConstant.themes.theme , 'Post', params, true)
}

export const updateThemeApi = (params) => {
    return Request(apiConstant.themes.theme , 'Put', params, true)
}

export const deleteThemeApi = (params) => {
    return Request(`${apiConstant.themes.removeTheme}`, 'Put', params, false)
}
//////////////////////////assets////////////////////////////

export const getAllAssetsApi = (params) => {
    return Request(apiConstant.assets.assetFile, 'Get', params, false )
}


// export const createAssetApi = (params) => {
//     return Request(apiConstant.assets.assetFile , 'Post', params, true)
// }

export const createAssetApi = (params) => {
    return Request(apiConstant.assets.assetFile, 'Post', params.data, true, params.progress, params.header, params.controller )
}

export const deleteAssetFileApi = (params) => {
    return Request(`${apiConstant.assets.assetFile}/${params.id}`, 'Delete', params.param, true)
}

////////////////////////////////// event Notes //////////////////////////////////////


export const getAllEventNotesApi = (params) => {
    return Request(`${apiConstant.eventNotes.manageEventNotes}?event_id=${params.event_id}`, 'Get')
}

export const uploadEventNoteApi = (params) => {
    return Request(apiConstant.eventNotes.manageEventNotes, 'Post', params.data, true, params.progress, params.header, params.controller )
}

export const deleteEventNoteApi = (params) => {
    return Request(`${apiConstant.eventNotes.manageEventNotes}/${params.id}`, 'Delete', params.param, true)
}

export const editNotesToEditorApi = (params) => {
    return Request(apiConstant.event.editNotes, "Patch", params, true);
};


//////////////////////////////// event songs //////////////////////////////////////////////


export const getAllEventSongsApi = (params) => {
    return Request(`${apiConstant.eventSongs.manageEventSongs}?event_id=${params.event_id}`, 'Get')
}

export const uploadEventSongApi = (params) => {
    return Request(apiConstant.eventSongs.manageEventSongs, 'Post', params.data, true, params.progress, params.header, params.controller )
}

export const deleteEventSongApi = (params) => {
    return Request(`${apiConstant.eventSongs.manageEventSongs}/${params.id}`, 'Delete', params.param, true)
}

export const editSongTextApi = (params) => {
    return Request(apiConstant.event.editSongText, "Patch", params, true);
};


/////////////////////////// guest review //////////////////////////////////////////////


export const registerGuestApi = (params) => {
    return UnauthRequest(apiConstant.guest.registerGuestReviewer , 'Post', params, true)
}

export const getGuestEventDetailsApi = (params) => {
    return UnauthRequest(apiConstant.guest.eventDetails, 'Get', params, false )
}

export const createGuestLogApi = (params) => {
    return UnauthRequest(apiConstant.guest.createLog , 'Post', params, true)
}

export const updateGuestRevisionLogsApi = (params) => {
    return UnauthRequest(`${apiConstant.revision.getRevisionLogs}/${params.id}`, 'Put', params.param, true )
}

export const deleteGuestRevisionLogApi = (params) => {
    return UnauthRequest(`${apiConstant.revision.deleteLogs}/${params.id}`, 'Delete', params.param, true)
}


///////////////////watch video link////////////////////////////////

export const watchVideoApi = (params) => {
    return Request(`${apiConstant.event.watchVideoLink}?slug=${params.slug}`, 'Get')
}

////////////////////////////// device info ///////////////////////////
export const createDeviceInfoApi = (params) => {
    return Request(apiConstant.device.deviceInfo , 'Post', params, true)
}

export const getDeviceInfoApi = (params) => {
    return Request(`${apiConstant.device.deviceInfo}/${params.slug}`, 'Get')
}


/////////////////general upload mechanism//////////////////////////////

export const uploadChunkPreflightApi = (params) => {
    return Request(`${apiConstant.user.uploadPreflight}?category=${params.category}` , 'Post', params.data, true)
}
export const uploadChunkPostFlightApi = (params) => {
    return Request(`${apiConstant.user.uploadPostflight}?category=${params.category}` , 'Put', params.data, true)
}


export const uploadChunkApi= (params) => {
    return Request(`${apiConstant.user.uploadChunk}?category=${params.data.category}`, 'Post', params.data.data, true, params.progress, params.header, params.controller )
}

/////////////////Unauth upload mechanism//////////////////////////////

export const uploadChunkPreflightGuestApi = (params) => {
    return UnauthRequest(`${apiConstant.user.uploadPreflight}?category=${params.category}` , 'Post', params.data, true)
}
export const uploadChunkPostFlightGuestApi = (params) => {
    return UnauthRequest(`${apiConstant.user.uploadPostflight}?category=${params.category}` , 'Put', params.data, true)
}


export const uploadChunkGuestApi= (params) => {
    return UnauthRequest(`${apiConstant.user.uploadChunk}?category=${params.data.category}`, 'Post', params.data.data, true, params.progress, params.header, params.controller )
}