import { Avatar, Box, Button, IconButton, Input, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CustomAlert from "../../events/components/CustomAlert"
import { updateReviewState } from "../../../redux/reviewSlice";
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from "../../../components/icons/CheckIcon";
import edit from '../../../assets/editExtendIcon.svg'
import { updateCreationState } from "../../../redux/eventCreationSlice";
import { afterValidate, getInitials, setInput, timeStampToDateString, timeStampToDayString, timeStampToNumberedDateString } from "../../../utils/commonServices";
import { LoadingButton } from "@mui/lab";
import { updateTeamState } from "../../../redux/teamSlice";
import CustomInput from "../../eventcreation/components/CustomInput";
import CustomAutocomplete from "../../eventcreation/components/CustomAutoComplete";
import CustomButton from "../../../components/CustomButton";
import { getErrorMsg } from "../../../utils/validator";
import { exampleTeamMembers } from "../../../constants/staticData";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import { updateProjectState } from "../../../redux/projectSlice";
import MembersList from "../../teams/components/MembersList";
import ProjectMembersList from "./ProjectMembersList";
// import { customTheme as theme } from "../../../theme/customTheme";
import { addTeamMembers } from "../../teams/actions";
import { addProjectMember } from "../actions";
import { getNamesFromIds, getRoleNamesFromIds } from "../../../commonactions/actions";
import { useNavigate } from "react-router-dom";
import useCustomTheme from "../../../theme/useCustomTheme";



const useStyles = makeStyles((theme) => ({

  expanded: {
    width:'60%'
  }
}));



const InviteProjectMembersPopup = (props) => {
    const {} = props

    const colors = ['red', 'pink', 'black'];
   
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchTerm, setSearchTerm] = useState('');
    const teamsReducer = useSelector((state) => state.root.teamsReducer);
    const projectReducer = useSelector((state) => state.projectReducer);
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const dropdownReducer = useSelector((state) => state.root.dropdownReducer);
    const [emailValidationFlag, setEmailValidationFlag] = useState(false);
    const [userValidationFlag, setUserValidationFlag] = useState(false);
    const [roleValidationFlag, setRoleValidationFlag] = useState(false);
    const [inviteButtonState, setInviteButtonState] = useState('Invite')
    const [theme ] = useCustomTheme()
    
    

    const [members, setMembers] = useState(commonReducer?.selected_project_members)
    const [isGlobalApprover, setIsGlobalApprover] = useState()
    // const [testmembers, setTestmembers] = useState([])
   
    const [searchedArray, setSearchedArray] = useState(members)
    const initialValue = {
      event_id:commonReducer?.production_event_id,
      user_id: "" ,
      role_id: ''  
    }
    const [pageData, setPageData] = useState(initialValue)

    const isTeamPublic = teamsReducer?.selected_team?.public
    const options = dropdownReducer.project_roles


    const remainingMembers = (isTeamPublic === true || isTeamPublic === undefined) ? teamsReducer.organization_members.filter(
      (member) => !commonReducer?.selected_project_members?.find((projectMember) => projectMember.id === member.id)
    ):  teamsReducer.selected_team_members?.users?.filter(
      (member) => !commonReducer?.selected_project_members?.find((projectMember) => projectMember.id === member.id)
    ) 

  

    // useEffect(()=>{
    //   setTestmembers(teamsReducer.organization_members)
    // },[teamsReducer.organization_members])

    
    // useEffect(()=>{
    //   setMembers(projectReducer.all_project_members)
    // },[projectReducer.all_project_members])

    useEffect(()=>{
        setSearchedArray(commonReducer?.selected_project_members)
        const temp = commonReducer?.selected_project_members?.find((item)=> item.id == commonReducer.user_id)
        if(!!temp) {
          setIsGlobalApprover(temp?.is_approver)
        }
       
      },[commonReducer?.selected_project_members])

 
  
    const inviteClickHandler = () => {
      setUserValidationFlag(true)
      setRoleValidationFlag(true)
      afterValidate(sendInvite)
      
    }
    
    const sendInvite =()=>{
    
      addProjectMember(pageData, dispatch, navigate, {commonReducer})
      setInviteButtonState("Invite Sent!")
      setTimeout(() => {
        setInviteButtonState('Invite')
        setUserValidationFlag(false)
        setRoleValidationFlag(false)
        setPageData(initialValue)
      }, 1500)

    }

    const handleClose = () => {
     !projectReducer.is_loading && dispatch(updateProjectState({invite_project_members_popup : false, invite_contributor_flag:false, invite_filmer_flag:false}))      
    };

  
    const handleAutocompleteChange = (event, value) => {
      // console.log({value})
      setPageData((last) => {
        return { ...last, role_id: value.id };
    });
    }; 

  const handleSearch = (e) => {
    // const searchedInput = e.target.value.toLowerCase().trim();
    // setSearchTerm(searchedInput);

    const searchedInput = e.target.value.toLowerCase();
    if (!searchedInput.startsWith(' ')) {
      setSearchTerm(searchedInput);
    }

    const updatedFiles = [...members];

    const results = updatedFiles?.filter(item =>
      item.name?.toLowerCase().includes(searchedInput) ||
      item.email?.toLowerCase().includes(searchedInput)||
      item.role?.name?.toLowerCase().includes(searchedInput)
    );
    setSearchedArray(results);
  };


    return (
        <CustomAlert 
          open={projectReducer.invite_project_members_popup} 
          handleClose={handleClose} 
          height={{xs:'420px', sm:'370px',md: '500px', xl:'620px'}}
          // width={{xs:'90%', md:'70%', xl:'60%'}}
          // height={{xs:'420px', md:'75%',lg:'70%',xl:'630px'}}
          width={{md:'65%',lg:'65%', xl:'1400px'}}
          rightComponent={
          <Box component='div' className= 'flex col a-start' sx={{paddingX:{xs:'10px',md:'1rem', lg:'1.2rem', xl:'2rem'},height:'100%',  overscrollBehaviorBlock:'contain', overflowY:'auto', width:'100%', justifyContent:{md:'center'}, paddingTop:{xs:2,sm:3, md:1, lg:0}}}>
            {/* <Box  sx={{height: '100%',  overscrollBehaviorBlock:'contain', overflowY:'auto', width:'100%', justifyContent:{sm:'center'}}} className='flex col a-start'>  */}
              <Typography sx={{fontSize:{xs:'8px',md:'9px',lg:'10px', xl:'14px'}, fontWeight:600, fontFamily:'Rubik', color:'#000'}} >Project Managers</Typography>
              <Box component='ul' pl={1} mt={0} style={{listStyleType: 'disc'}} mb={{xs:1, md:1.5, lg:2.5}}>
                <Typography component='li' sx={{fontSize:{xs:'7px',md:'8px',lg:'9px', xl:'12px'}, fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>Manage project members</Typography>
                <Typography component='li' sx={{fontSize:{xs:'7px',md:'8px',lg:'9px', xl:'12px'}, fontFamily:'Rubik',textAlign:'justify', color:'#000'}}>Edit project details</Typography>
                <Typography component='li' sx={{fontSize:{xs:'7px',md:'8px',lg:'9px', xl:'12px'}, fontFamily:'Rubik',textAlign:'justify', color:'#000'}}>Manage all media files of the project</Typography>
                <Typography component='li' sx={{fontSize:{xs:'7px',md:'8px',lg:'9px', xl:'12px'}, fontFamily:'Rubik',textAlign:'justify', color:'#000'}}>Create and send edit requests</Typography>
                <Typography component='li' sx={{fontSize:{xs:'7px',md:'8px',lg:'9px', xl:'12px'}, fontFamily:'Rubik',textAlign:'justify', color:'#000'}}>View and download final video</Typography>
                <Typography component='li' sx={{fontSize:{xs:'7px',md:'8px',lg:'9px', xl:'12px'}, fontFamily:'Rubik',textAlign:'justify', color:'#000'}}>Approve final video if you are an Admin or has been Set as Approver by an Admin</Typography>
              </Box>

              <Typography sx={{fontSize:{xs:'8px',md:'9px',lg:'10px', xl:'14px'}, fontWeight:600, textAlign:'justify', fontFamily:'Rubik', color:'#000'}}>Collaborators</Typography>
              <Box component='ul' pl={1} style={{listStyleType: 'disc'}} mt={0} mb={{xs:1, md:1.5, lg:2.5}}>
                <Typography component='li' sx={{fontSize:{xs:'7px',md:'8px',lg:'9px', xl:'12px'}, fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>View list of other team members</Typography>
                <Typography component='li' sx={{fontSize:{xs:'7px',md:'8px',lg:'9px', xl:'12px'}, fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>Manage their uploaded media files</Typography>
              </Box>
              <Typography sx={{fontSize:{xs:'8px',md:'9px',lg:'10px', xl:'14px'}, fontWeight:600, textAlign:'justify', fontFamily:'Rubik', color:'#000'}}>Filmers</Typography>
              <Box component='ul' pl={1} style={{listStyleType: 'disc'}} mt={0}  mb={{xs:1, md:1.5, lg:2.5}}>
                <Typography component='li' sx={{fontSize:{xs:'7px',md:'8px',lg:'9px', xl:'12px'}, fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>Upload, view and delete their files in the project</Typography>
              </Box>
              <Typography sx={{fontSize:{xs:'8px',md:'9px',lg:'10px', xl:'14px'}, fontWeight:600, textAlign:'justify', fontFamily:'Rubik', color:'#000'}}>Reviewers</Typography>
              <Box component='ul' pl={1} style={{listStyleType: 'disc'}} mt={0}  mb={{xs:1, md:1.5, lg:2.5}}>
                <Typography component='li' sx={{fontSize:{xs:'7px',md:'8px',lg:'9px', xl:'12px'}, fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>View edited video</Typography>
                <Typography component='li' sx={{fontSize:{xs:'7px',md:'8px',lg:'9px', xl:'12px'}, fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>View edit requests</Typography>
              </Box>
              <Typography sx={{fontSize:{xs:'8px',md:'9px',lg:'10px', xl:'14px'}, fontWeight:600, textAlign:'justify', fontFamily:'Rubik', color:'#000'}}>Approvers</Typography>
              <Box component='ul' pl={1} style={{listStyleType: 'disc'}} mt={0}  mb={{xs:1, md:1.5, lg:2.5}}>
                <Typography component='li' sx={{fontSize:{xs:'7px',md:'8px',lg:'9px', xl:'12px'}, fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>View edited video </Typography>
                <Typography component='li' sx={{fontSize:{xs:'7px',md:'8px',lg:'9px', xl:'12px'}, fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>View and create edit requests</Typography>
                <Typography component='li' sx={{fontSize:{xs:'7px',md:'8px',lg:'9px', xl:'12px'}, fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>View and download final accepted video</Typography>
              </Box>
            {/* </Box> */}
          </Box>}
          >       
            <Box sx={{display:'flex', flexDirection:'column', height:'100%'}} px={4} pt={3} pb={2}>
              <Box flexGrow={1} pt={1} >
                  <Typography mb={1} sx={{fontWeight:600, fontFamily:'Rubik', fontSize:{xs:'16px',sm:'16px',md:'18px',lg:'20px',xl:'27px'}, color:'#000'}}>Select a member to join your Project</Typography>
                  <Box gap={2} style={{display:'flex'}} mb={1}>
                            <Box style={{width:'40%'}}>

                              {/* <CustomInput
                                onFocus={()=>setEmailValidationFlag(false)}
                                onBlur={()=>setEmailValidationFlag(true)}
                                required
                                errorFlag={
                                  emailValidationFlag &&
                                  getErrorMsg("email", pageData?.email) != ""
                                }
                                errorMsg={getErrorMsg("email", pageData?.email)}
                                type="email"
                                max={40}
                                placeholder="Add members by email..."
                                onChange={(event) => {
                                  setInput(
                                    pageData?.email != ""
                                    ? event.target.value
                                    : event.target.value.trim(),
                                    "email",
                                    pageData,
                                    setPageData
                                  );
                                }}
                                value={pageData?.email}
                              /> */}
                               <CustomAutocomplete
                                  onFocus={()=>setUserValidationFlag(false)}
                                  onBlur={()=>setUserValidationFlag(true)}
                                  options={remainingMembers}
                                  placeholder='Select...'
                                  required
                                  disabled={projectReducer.is_loading}
                                  errorFlag={
                                  userValidationFlag &&
                                  getErrorMsg("member", pageData?.user_id) != ""
                                  }
                                  errorMsg={getErrorMsg("member", pageData?.user_id)}
                                  onChange={(event, value)=>{
                                    setPageData((last) => {
                                        return { ...last, user_id: value.id };
                                    });
                                  }}
                                  getOptionLabel={(option) =>
                                  Object.keys(option).length > 0 ? option.name : ""
                                  }
                                  value={getNamesFromIds(pageData?.user_id, remainingMembers)}
                                  renderOption={(props, option, { selected , index}) => (
                                    <li {...props} >       
                                        <Avatar
                                          sx={{
                                          borderRadius: '5px',
                                          backgroundColor: colors[index % colors.length],
                                          color: 'white',
                                          width: { xs: '18px', sm: '20px', md: '22px', lg: '23.5px', xl: '35px' },
                                          height: { xs: '18px', sm: '20px', md: '22px', lg: '23.5px', xl: '35px' },
                                          paddingRight: 0,
                                          marginRight: 0,
                                          fontSize: { xs: '7px', md: '8px', lg: '8px', xl: '12px' },
                                          textTransform: 'uppercase',
                                          fontFamily: 'Rubik',
                                          fontWeight:600,
                                          mr:2
                                          }}
                                        >
                                          {getInitials(option.name)}
                                        </Avatar>
                                        <Box sx={{'& span': {color: theme.palette.mode === 'light' ? '#586069' : '#8b949e',},}}>{option.name}</Box>
                                    </li>
                                )}
                              />
                            </Box>
                            <Box style={{width:'25%'}}>         
                              <CustomAutocomplete
                                options={options}
                                placeholder='Set as...'
                                onFocus={() => {
                                  setUserValidationFlag(true)
                                  setRoleValidationFlag(false)
                                }}
                                disabled={projectReducer.is_loading}
                                onBlur={() => setRoleValidationFlag(true)}
                                required
                                errorFlag= {roleValidationFlag && getErrorMsg("role_type", pageData?.role_id) != ""}
                                errorMsg= {getErrorMsg("role_type", pageData?.role_id)}
                                onChange={handleAutocompleteChange}
                                getOptionLabel={(option) =>
                                Object.keys(option).length > 0 ? option.name : ""
                                }
                              //  value={options.filter(opt => opt.id == pageData.role_id)[0]}
                               value={getRoleNamesFromIds(pageData?.role_id, dropdownReducer?.project_roles)}
                                />
                            </Box>
                            <Box>
                              <CustomButton variant='contained' color='secondary' disabled={projectReducer.is_loading} btnText='Add'  type='button' onClick={inviteClickHandler}  sx={{
                               backgroundColor:theme.palette.secondary.main, 
                               borderRadius:'5px', border:'none', fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, padding:'10px', width:'6.1rem', fontWeight:600, textTransform:'none', height:{xs:'18px',sm:'18px',md:'20px',lg:'22px',xl:"36px"}, fontFamily:'Rubik'}}/>
       
                            </Box>
                  </Box>
                  <Box className='flex col' gap={1.5}>
                    <Box className='flex j-between a-center' style={{width:'90%', marginTop: '16px'}}>
                      <Typography sx={{fontWeight:600, fontFamily:'Rubik', color:'#000', fontSize:{xs:'16px',sm:'16px',md:'18px',lg:'20px',xl:'27px'}}}>Members ({searchedArray?.length})</Typography>
                      <Box id="search" className='flex j-end'>
                        <Input
                          id="search-input"
                        value={searchTerm}
                        onChange={handleSearch}
                        disabled={projectReducer.is_loading}
                        inputProps={{ maxLength: 50 }}
                        sx={{
                          //  minWidth: '160px',
                            // borderBottom: 1,
                            borderColor: "transparent",
                            input: {
                                fontSize: { xs: '12px', md: '12px', lg: '13px', xl: '16px' },
                                fontWeight: 400,
                                color: "#000000",
                              
                            },
                           
                            justifySelf:'flex-end',
                            width:'60%', 
                            transition: 'width 0.3s ease',
                          
                            // mr:5
                        }}
                        placeholder="Search"
                        startAdornment={
                            <IconButton disabled={projectReducer.is_loading} sx={{paddingBottom:0.5, fontSize: {xs:'12px',md:'14px',lg:'17px',xl:'24px'}, '& .MuiSvgIcon-root':{fontSize:{xs:'12px',md:'14px',lg:'15px',xl:'20px'}}, paddingRight:2}} >
                                <SearchIcon style={{ color: "#000000" }} />
                            </IconButton>
                        }
                        />
                    </Box>
                    </Box>
                <Box pr={2}>
                <div style={{width:{xs:'90%', md:'80%'}, marginTop:'8px'}}>
                          <Box mb={1} className='flex a-center' gap={2}>
                            <Box flex={8} className='flex col' sx={{fontSize:{xs:'9px', md:'10px',lg:'13px', xl:'18px'}, fontWeight:600, fontFamily:'Rubik', color:'#000000'}}>
                              <Typography sx={{ fontSize: {xs:'9px', md:'10px',lg:'12px', xl:'16px'}, fontWeight: 500, color: '#000000',  fontFamily:'Rubik'}}>
                                 Name
                              </Typography>
                            </Box>

                            <Box flex={6} className='flex a-center' style={{justifyContent:'end'}}>
                              <Typography sx={{ fontSize: {xs:'9px', md:'10px',lg:'12px', xl:'16px'}, fontWeight: 500, color: '#000000', fontFamily:'Rubik', textAlign:'right'}}>
                                Role
                              </Typography>
                            </Box>

                       

                            <Box flex={2} className='flex a-center' style={{justifyContent:'end'}}>
                              <Typography sx={{ fontSize: {xs:'9px', md:'10px',lg:'12px', xl:'16px'}, fontWeight: 500, color: '#000000', fontFamily:'Rubik', textAlign:'right'}}>
                                 Set Approver
                              </Typography>
                            </Box>
                            
                           </Box>
                        </div>
                
                    <Box sx={{height:'22vh', overflowY:'auto'}}>

                      {searchedArray?.length > 0 ? searchedArray?.map((member, index) => {
                        return  <ProjectMembersList disableApproverToggle = {!isGlobalApprover} member={member} index={index} searchedArray={searchedArray} setTestmembers={setSearchedArray} roleOptions={options}/>
                      }): 
                       <Box className= 'flex col j-center a-center' sx={{overflowY: "initial", whiteSpace: 'normal'}} height='100%' width='100%'>
                          <Typography sx={{fontSize: 20, color: '#878887', fontWeight: 300, textAlign: 'center', fontFamily:'Rubik'}} >No results found.
                          </Typography>
                        </Box>
                      }
                      {/* <MembersList testmembers={testmembers} /> */}
                     
                
                    </Box>
                  </Box>

                  </Box>
  
              </Box>

              {/* <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-end', alignItems:'end'}}>
               <Box pr={1.5}>

               { requestStatus == 'loading'  ? <LoadingButton
                  
                    sx={{
                        '& .MuiLoadingButton-loadingIndicator': {
                            color: theme.palette.primary.main,
                        },
                        height: {xs:'36px',sm:'40px',md:'45px', lg:'51px',xl:'75px'},
                       minWidth: {xs:'36px !important',sm:'40px !important',md:'45px !important', lg:'51px !important',xl:'75px !important'},
                        backgroundColor: '#08231B'
                    }}
                    loading={requestStatus == 'loading'}
                    variant="outlined"
                    disabled
                    >
                   
               </LoadingButton> :
               <CheckIcon onClick= {submitRequestClickHandler}  sx={{fontSize:{xs:'40px',sm:'44px',md:'50px', lg:'56px',xl:'82px'}, cursor: 'pointer'}}/>
               }
                 
                </Box>
              <Box>    
                <Button 
                variant="text"
                onClick={handleClose}
                 sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, width:{xs:'70px',sm:'70px',md:'80px',lg:'90px',xl:'120px'}, fontFamily:'Rubik', textTransform:'none', letterSpacing:'0.65px', textDecoration:'underline'}}
                >
                Or Go Back
               </Button>
              </Box>
            </Box> */}
          </Box>
       
        </CustomAlert>
      )
}

export default InviteProjectMembersPopup