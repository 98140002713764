import { Box,  Typography, Avatar, IconButton} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useMemo, useRef, useState } from "react";
import CollaboratorCard from "../events/components/CollaboratorCard";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useUploadedList from "../../hooks/useUploadedList";
import useSequentialVideoUpload from "../../hooks/useSequentialVideoUpload";
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { useEffect } from "react";
import CustomUserCard from "../events/components/CustomUserCard";
import "@fontsource/rubik";
import AuthLayout from "../layouts/AuthLayout";
import useConsentDetail from "../events/hooks/useConsentDetail";
import EventDetailCard from "../events/components/EventDetailCard";
import FilmingTutorialCard from "../events/components/FilmingTutorialCard";
import VideoPlayer from "./components/VideoPlayer";
import RequestLog from "./components/RequestLog";
import SubmitChangeRequestCard from "./components/SubmitChangeRequestCard";
import FinalVideoCompletedCard from "./components/FinalVideoCompletedCard";
import useAcceptFinalVideo from "./hooks/useAcceptFinalVideo";
import useRevisionRequests from "./hooks/useRevisionRequests";
import { updateReviewState } from "../../redux/reviewSlice";
import PopupVideoPlayer from "../../components/PopupVideoPlayer";
import { updateState } from "../../redux/commonSlice";
import useCheckVideoArrival from "./hooks/useCheckVideoArrival";
import useEventDetails from "../events/hooks/useEventDetails";
import VideoEditedCompletedCard from "./components/VideoEditedCompletedCard";
import ReEditingStartedCard from "./components/ReEditingStartedCard";
import SubmitChangeRequestTutorialPopup from "./components/SubmitChangeRequestTutorialPopup";
import butterfly from './../../assets/butterfly_navigation.png'
import { checkNewVideoArrival, getEventDetails, viewProjectMembers } from "../events/actions";
import { getUserRoles } from "../../commonactions/actions";
import { setSnackbar } from "../../redux/messageSlice";
import { PERMISSION } from "../../constants/constants";
import usePermissions from "../../hooks/usePermissions";
import CustomProtectedRoute from "../../components/CustomProtectedRoute";
import ProjectMembersCard from "../events/components/ProjectMembersCard";
import InviteProjectMembersPopup from "../events/components/InviteProjectMembersPopup";
import ViewProjectMembersPopup from "../events/components/ViewProjectMembersPopup";
import AssignOwnerPopup from "../events/components/AssignOwnerPopup";
import RemoveUserConfirmationPopup from "../events/components/RemoveUserConfirmationPopup";
import RemoveOwnerPopup from "../events/components/RemoveOwnerPopup";
import ViewNotesToEditorPopup from "../events/components/ViewNotesToEditorPopup";
import UploadDocumentsPopup from "../events/components/UploadDocumentsPopup";
import UploadSongPopup from "../events/components/UploadSongPopup";
import ViewSongDescriptionPopup from "../events/components/ViewSongDescriptionPopup";
import { checkTokenSession } from "../dashboard/actions";
import { createNewRevision, disableRevisionTutorialRequest } from "./actions";



const useStyles = makeStyles((theme) => ({
    container: {
      display:'flex',
      width:'100%',
      minWidth:'860px',
       height:'85vh',
     // height:'100%',  
  },
    center: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly'
    },
    leftContainer: {
         width:'21.1%',
          display:'flex',
          flexDirection:'column',
          justifyContent: 'flex-start',
          marginBottom:'41px',  
         // paddingLeft:'2.24%',
          height:'100%',
        
    },
    rightContainer:{
      width:'77.2%',
      display:'flex',
      flexDirection:'column',
     // marginRight:'4.9%',
      marginBottom:'51px',
      marginLeft:'1.7%',
      height:'100%',
     
  },
    scroll: {
      overflowY: 'scroll',
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
  
    }
  }));

const CompletedEventPage = (props) => {
  const {uploadProps} = props;

  const {
    selectedFiles, setSelectedFiles,
    uploadsInProgress, setUploadsInProgress,
    uploadStatus, setUploadStatus,
    progress, setProgress, uploadFiles,
    requestController, setRequestController,
    actionAfterSuccessfulUpload
  } = uploadProps;
    const classes = useStyles()
    // const { videoTitle, videoSrc, } = props
    const [copyButtonText, setCopyButtonText] = useState("COPY LINK");
    const [shareButtonText, setShareButtonText] = useState("INVITE");
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [hasPermission] = usePermissions();
  
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const teamsReducer = useSelector((state) => state.root.teamsReducer);
    const projectReducer = useSelector((state) => state.projectReducer);
    const reviewEventReducer = useSelector((state) => state.reviewEventReducer);
    const permissionsReducer = useSelector((state) => state.permissionsReducer);
   // const [refetchEventDetail] = useEventDetails(commonReducer.production_event_id)
    const [eventData, setEventData] = useState({})
    // const [uploadedVideosArray, setUploadedVideosArray] = useState([]);
    // const [checkVideoArrival, recheckVideoArrival] = useCheckVideoArrival();
    // const [uploadedVideos, refetchList] = useUploadedList(true);
    const [getConsentDetails] = useConsentDetail();
    const statusRef = useRef();
    const finalVideoRef = useRef();
    const underRevisionRef = useRef(false)
    const isFromTeamsDashboard =  !!teamsReducer.selected_team ? true : false;
    const backButtonTitle = isFromTeamsDashboard ? "Back to Team Dashboard" : "Back to Main Dashboard";
    const [showPage, setShowPage] = useState(false)

    const event = !!teamsReducer.selected_team ? teamsReducer.events?.find((event) => event.id == commonReducer.production_event_id) : commonReducer.events?.find((event) => event.id == commonReducer.production_event_id)

    // const { files, 
    //   setFiles,
    //   progress, 
    //   uploadStatus,
    //   setUploadStatus, 
    //   setProgress,
    //   isLoading,
    //   requestController, 
    //   setRequestController,
    //   stopUpload, 
    //   stopList } = useSequentialVideoUpload(commonReducer.production_event_id, false, uploadedVideos)  // useSequentialVideoUpload(eventId: , isGuestUser, uploadedVideos) 
     
     
      useEffect(() => {
        statusRef.current = commonReducer.event_details?.status_type_order;
        if(statusRef.current === 4) {
          if(commonReducer.event_details.final_video_id === commonReducer.event_details.revision.revision_video_id) {
            //dispatch(updateState({new_video: false}));
            underRevisionRef.current =  commonReducer.event_details?.revision.submitted;
          } else {
            underRevisionRef.current =  false
          }
        }
    }, [commonReducer.event_details.status_type_order, commonReducer.event_details?.revision.submitted, commonReducer.event_details.final_video_id, commonReducer.event_details.revision.revision_video_id]);
    useEffect(()=> {
      finalVideoRef.current = commonReducer.event_details.final_video_id
    }, [commonReducer.event_details.final_video_id])

    useEffect(()=>{
      checkTokenSession(dispatch)
      getUserRoles(dispatch, navigate, permissionsReducer, {event_id: commonReducer?.production_event_id}, getUserCallback)
    },[])
  
    useEffect(()=> {
      getEventDetails({id: commonReducer.production_event_id}, dispatch);
      
     const intervalId = setInterval( () => {
        if(statusRef.current == 3 || (statusRef.current == 4 && underRevisionRef.current == true)) {
          checkNewVideoArrival({event_id: commonReducer.production_event_id, final_video_id: finalVideoRef.current}, dispatch)
        }
      }, 15000);
     
      return () => clearInterval(intervalId);
    
  
    },[])
  

      // useEffect(()=> {
      //   if(commonReducer.new_video == true) {
      //     refetchEventDetail()
      //   }
      // }, [commonReducer.new_video])

    // useEffect(() => {
     
    //   setUploadedVideosArray(uploadedVideos) 
    // }, [uploadedVideos])
    
  
    // useEffect(() => {
    //   refetchList();
    // }, [commonReducer.production_event_id])

    useEffect(()=> {
      if(reviewEventReducer.request_changes == true) {
        getEventDetails({id: commonReducer.production_event_id}, dispatch)
      }
    }, [reviewEventReducer.request_changes])
    
    useEffect(() => {
      const selectedEvent = commonReducer.event_details

      if (Object.keys(selectedEvent)?.length > 0) {
        setEventData({ name: selectedEvent.name, editor_start_date: selectedEvent.editor_start_date, start: selectedEvent.start_date, end: selectedEvent.end_date, eventType: selectedEvent.event_type, code: selectedEvent.collab_code, status: selectedEvent.status_type , consentStatus: selectedEvent.consent_accepted})
      }

    if(commonReducer.event_details.revision.id == null || commonReducer.event_details.revision.submitted == true) {
        if(commonReducer.show_revision_tutorial) {
          dispatch(updateReviewState({open_change_request_tutorial_popup : true}))
           disableRevisionTutorialRequest(dispatch, navigate, {commonReducer})
        }
         createNewRevision(dispatch, navigate, {commonReducer}, {event_id: commonReducer.production_event_id, final_video_id: commonReducer.event_details.final_video_id})
        } 

     
    }, [commonReducer.production_event_id, commonReducer.events])
  


    // const copyInvite = ()=> {
    //   setShareButtonText("COPIED!")
    //   navigator.clipboard.writeText(`Hi! ${commonReducer.user_name} has invited you to be a contributor to their event. Please visit the given link and use following invitation code to collaborate to this event\n\nInvitation Code:  ${eventData.code}\n\n\n ${window.location.host}`)
    //   setTimeout(() => {
    //     setShareButtonText("INVITE")
    //   }, 1500)
    // }
  
    const shareClickHandler = () => {
      const dataToBeShared = {
       // title: "This title is for sharing",
        text: `Hi! ${commonReducer.user_name} has invited you to be a contributor to their event. Please visit the given link and use following invitation code to collaborate to this event\n\nInvitation Code:  ${eventData.code}\n\n\n`,
       // url: `/guestuploads?e=${commonReducer.production_event_id}&n=${eventData?.name?.replace(/ /g, "%20")}&s=${eventData?.start}&end=${eventData?.end}`,
        url: `/`
      };
      // Check if navigator.share is supported by the browser
    // console.log(navigator.share)
      if (navigator.share) {
        navigator
          .share(dataToBeShared)
          .then(() => {
          })
          .catch(() => {
          });
      } else {
       // console.log("Sorry! Your browser does not support Web Share API");
      }
    }
  
   const getUserCallback = (permission)=>{
    if(!permission[PERMISSION.PROJECT.REVISION.PAGE_VIEW])  {
      // console.log("slected team", teamsReducer.selected_team)
       isFromTeamsDashboard? navigate('/dashboard/teams') : navigate('/dashboard')
      dispatch(setSnackbar({flag: true, type: 'error', message: "ACCESS DENIED!"}))
    } else {
      setShowPage(true)
    }
   }


   useEffect(()=> {
    hasPermission(PERMISSION.PROJECT.MEMBER.VIEW) && viewProjectMembers({event_id: commonReducer.production_event_id}, dispatch, navigate, {commonReducer}) 
},[permissionsReducer.permissions_master[PERMISSION.PROJECT.MEMBER.VIEW]])
  
 
    const backCLickHandler = ()=> {
      // dispatch(updateReviewState({request_changes: false}))
      navigate("/productionevent", {replace: true})
    }

  // useEffect(()=> {
  //   if(!hasPermission(PERMISSION.PROJECT.REVISION.PAGE_VIEW) ) {
  //     console.log("slected team", teamsReducer.selected_team)
  //      !!teamsReducer.selected_team ? navigate('/dashboard/teams') : navigate('/dashboard')
  //     dispatch(setSnackbar({flag: true, type: 'error', message: "ACCESS DENIED!jakdshcbajkhda"}))
  //   }
  // }, [permissionsReducer.permissions_master, teamsReducer.selected_team])
    

  return (
  
    showPage && <AuthLayout uploadProps = {props.uploadProps} sx={{ padding: '2.5% 3.9% 2.1% 2.2%'}}>
        <Box gap={2} className='flex row a-start j-end'>
        {/* <img onClick={()=> window.location.href='https://butterfly.lucimoments.com'} src={butterfly} style={{ borderRadius: '5px', width: '190px', cursor: 'pointer'}} /> */}
                  <CustomUserCard/>
                </Box>
                <Box container className={classes.container}>
        <Box className={classes.leftContainer}>
              <Box sx={{height:{xs:'400px',sm:'400px',md:'58vh',lg:'62vh',xl:'70vh'}, marginBottom:{xs:'15px',md:'20px',lg:'23px',xl:'35px'}}}>
                 <EventDetailCard eventData={eventData}/>
              </Box>
              <Box  sx={{height:{xs:'60px',sm:'80px',md:'16.1vh',lg:'18.1vh',xl:'16.1vh'}}} >
                <FilmingTutorialCard onClick= {()=> dispatch(updateState({play_tutorial: true})) } clickable = {true} />
              </Box>
             
        </Box>
  
        <Box className={classes.rightContainer}>
              <Box sx={{width:'100%', }}>
                <Box gap={1} sx={{display:'flex', justifyContent:'start', alignItems:'center', color:'black' }}>
                    <IconButton onClick={backCLickHandler}>
                      <KeyboardBackspaceOutlinedIcon sx={{color:'black',}}/>
                    </IconButton>
                    <Typography sx={{fontFamily: 'Rubik', fontSize:{xs:'10px',sm:'11px',md:'12px',lg:'14px',xl:'20px'}}}>{backButtonTitle}</Typography>
                </Box>
              </Box>
  
              <Box style={{display:'flex', gap:'2.34%'}} sx={{ marginBottom:{xs:'15px',sm:'15px',md:'15px',lg:'20px',xl:'30px'},  marginTop:{xs:'8px', sm:'10px', md:'12px',lg:'14px',xl:'23px'}, width:'100%', }}>

                  <Box sx={{height:{xs:'100px',sm:'100px', md:'120px',lg:'19.5vh',xl:'20vh'}, width:'26%'}} container  >
                      {/* <CollaboratorCard shareClickHandler={shareClickHandler} isDisabled = {commonReducer?.event_details?.status_type == 'Complete' || commonReducer?.event_details?.status_type == 'Review'} /> */}
                      <ProjectMembersCard
                        //  getFirstLetters={getFirstLetters} 
                         shareClickHandler={shareClickHandler} 
                        //  copyInvite={copyInvite} 
                         buttonState={shareButtonText} 
                         isDisabled = {!hasPermission(PERMISSION.PROJECT.MEMBER.VIEW)}
                      />
                  </Box>
                  <Box sx={{height:{xs:'100px',sm:'100px', md:'120px',lg:'19.5vh',xl:'20vh'}, width:'74%'}} container>
          
                           { 
                              commonReducer?.event_details?.status_type == 'Complete' ?  <FinalVideoCompletedCard/> : 
                               ( reviewEventReducer.request_changes ? <SubmitChangeRequestCard/> : 
                                   ( commonReducer.event_details?.revision?.submitted == true && commonReducer.event_details?.final_video_id == commonReducer.event_details?.revision?.revision_video_id ? <ReEditingStartedCard /> :         
                                        <VideoEditedCompletedCard message = {commonReducer?.event_details?.revision?.id == null || commonReducer?.event_details?.revision?.submitted != true ? "Your Lucivideo is ready!" : "Updates have been made to your Lucivideo."} />
                                   )
                               )
                           }
                           
                  </Box>
              </Box>
  {/* /////////////////main box for review stage//////////////////////// */}
              <Box sx={{display:'flex', height:{xs:'60%',sm:'60%',md:'62%',lg:'64%',xl:'64%'}, width:'100%',  backgroundColor:'#ffffffbf', boxShadow:'0px 5px 15px #00000029', borderRadius:'10px', alignItems:'center', }} >
                    {/* <Box> */}
                    <Box sx={{height:'100%', width: (reviewEventReducer.request_changes && commonReducer.event_details.status_type != "Complete" )? '70%':'100%', display:"flex", alignItems: 'flex-start' , paddingTop:'10px', justifyContent:'center', overflowY:'auto'}}>
                  {  (!hasPermission(PERMISSION.PROJECT.REVISION.FINAL_VIDEO.VIEW) &&  commonReducer.event_details.status_type_order === 5) ? 
                  <Box height='70%' className= 'flex col j-center' mt={6} ><Typography variant="h3">Video not available for view </Typography> </Box>:  <VideoPlayer/>}
                    </Box>
                    {reviewEventReducer.request_changes && <Box sx={{height:'100%', mr: 3, width:'30%', display: (!reviewEventReducer.request_changes || commonReducer.event_details.status_type != "Review") && 'none' , maxHeight:'668px'}}>
                        <RequestLog/>
                    </Box>}
                    {/* </Box> */}
             
              </Box>
        
        </Box>  
        </Box> 
        {
            commonReducer.play_tutorial == true && <PopupVideoPlayer />
       }
       {reviewEventReducer.open_change_request_tutorial_popup == true && <SubmitChangeRequestTutorialPopup />}
              
       {projectReducer.invite_project_members_popup == true && <InviteProjectMembersPopup/>}

       {projectReducer.view_project_members_popup == true && <ViewProjectMembersPopup />}

       {projectReducer.assign_owner_popup == true && <AssignOwnerPopup/>}

       {projectReducer.remove_user_confirmation_popup == true && <RemoveUserConfirmationPopup/>}

       {projectReducer.remove_owner_popup == true && <RemoveOwnerPopup/>}

       {projectReducer?.view_notes_to_editor_popup && <ViewNotesToEditorPopup event={event}/>}

       {projectReducer?.upload_documents_to_editor_popup && <UploadDocumentsPopup uploadProps = {uploadProps} />}

       {projectReducer?.upload_song_popup && <UploadSongPopup uploadProps = {uploadProps}/>}
       {projectReducer?.song_desc_popup && <ViewSongDescriptionPopup />}
     
     </AuthLayout> 

  )
}

export default CompletedEventPage